(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name home.statistics.factory:HTMLToCSVParser
   *
   * @description
   *
   */
  angular
    .module('neo.home.statistics')
    .factory('HTMLToCSVParser', HTMLToCSVParser);

  function HTMLToCSVParser() {
    return {
      parse: function (table) {
        var csvString = '';
        for (var i = 0; i < table.rows.length; i++) {
          var rowData = table.rows[i].cells;
          for (var j = 0; j < rowData.length; j++) {
            csvString = csvString + rowData[j].innerText + ",";
          }
          csvString = csvString.substring(0, csvString.length - 1);
          csvString = csvString + "\n";
        }
        csvString = csvString.substring(0, csvString.length - 1);
        return csvString;
      }
    };
  }
}());
